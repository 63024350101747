<template>
  <el-dialog
    title="Detalhes do abastecimento"
    top="35px"
    destroy-on-close
    v-model="shouldShow"
    width="90%"
  >
    <template #default v-if="pageDisplay === 'info'">
      <el-descriptions
        :column="3"
        size="large"
        border
        v-show="pageDisplay === 'info'"
      >
        <el-descriptions-item>
          <template #label>Quantidade:</template>
          {{ numberFormatter.format(Number(supply?.execution?.quantity) || 0) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Valor Total:</template>
          {{ currencyFormatter.format(sumTotalAmount()) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Odômetro</template>
          {{ Number(supply?.execution?.odometer) }}
        </el-descriptions-item>
      </el-descriptions>
      <el-tabs class="mt-2" type="border-card" v-show="pageDisplay === 'info'">
        <el-tab-pane label="Localização"
          ><div id="mapDiv" style="height: 450px"></div
        ></el-tab-pane>
        <el-tab-pane label="Selfie"
          ><div style="height: 450px">
            <el-image
              style="width: 300; height: 400px"
              :src="getImageSrc(supply.execution?.selfie_image_url)"
              fit="contain"
            ></el-image></div
        ></el-tab-pane>
        <el-tab-pane label="Odômetro"
          ><div style="height: 450px">
            <el-image
              style="width: 300px; height: 400px"
              :src="getImageSrc(supply.execution?.odometer_image_url)"
              fit="contain"
            ></el-image></div
        ></el-tab-pane>
      </el-tabs>
    </template>
    <template #default v-else>
      <div v-if="supply.fuel">
        <el-row type="flex" justify="start"
          ><h5>{{ supply.fuel_formatted }}</h5>
        </el-row>
        <base-input
          v-model="supply_.quantity"
          type="money"
          :placeholder="'Combustível: ' + supply.fuel_formatted"
          label="Quantidade:"
        ></base-input>
        <base-input
          v-model="supply_.amount"
          type="money"
          :placeholder="'Combustível: ' + supply.fuel_formatted"
          label="Valor:"
        ></base-input>
      </div>
      <div v-if="supply.fuel2">
        <el-row type="flex" justify="start"
          ><h5>{{ supply.fuel2_formatted }}</h5>
        </el-row>
        <base-input
          v-model="supply_.quantity2"
          type="money"
          :placeholder="'Combustível: ' + supply.fuel2_formatted"
          label="Quantidade:"
        ></base-input>
        <base-input
          v-model="supply_.amount2"
          type="money"
          :placeholder="'Combustível: ' + supply.fuel2_formatted"
          label="Valor:"
        ></base-input>
      </div>
      <div v-if="supply.lubrification">
        <el-row type="flex" justify="start"
          ><h5>{{ supply.lubrification_formatted }}</h5>
        </el-row>
        <base-input
          v-model="supply_.lub_quantity"
          type="money"
          :placeholder="'Combustível: ' + supply.lubrification_formatted"
          label="Quantidade:"
        ></base-input>
        <base-input
          v-model="supply_.lub_amount"
          type="money"
          :placeholder="'Combustível: ' + supply.lubrification_formatted"
          label="Valor:"
        ></base-input>
      </div>
      <div>
        <el-row type="flex" justify="start"><h5>Odômetro</h5> </el-row>
        <base-input
          v-model="supply_.odometer"
          type="money"
          :minimumFractionDigits="0"
          label="Total:"
        ></base-input>
      </div>
    </template>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button
          v-show="pageDisplay === 'info'"
          type="warning"
          plain
          @click="togglePageDisplay"
          >Editar</el-button
        >
        <el-button
          v-show="pageDisplay === 'edit'"
          type="warning"
          plain
          @click="togglePageDisplay"
          >Voltar</el-button
        >
        <el-button
          type="text"
          class="button"
          @click="shouldShow = false"
          v-show="pageDisplay === 'info'"
          >Fechar</el-button
        >
        <el-button
          type="text"
          class="button"
          :loading="isLoadingSave"
          v-show="pageDisplay === 'edit'"
          @click="saveSupplyExecution"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["supply", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      pageDisplay: "info",
      isLoadingSave: false,
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }),
      accessToken:
        "pk.eyJ1IjoiamFjb25zbW9yYWlzIiwiYSI6ImNrNW1uMGNrMjExbGQzcG83M3EwNDJlcWYifQ.astqW0QEC039TVEbzfo-lA",
      mapBox: null,
      supply_: this.supply?.execution || {},
    };
  },
  mounted() {
    this.initializeMap();
  },
  beforeUnmount() {
    this.mapBox?.remove();
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    togglePageDisplay() {
      if (this.pageDisplay === "info") {
        this.pageDisplay = "edit";
        this.mapBox?.remove();
      } else {
        this.pageDisplay = "info";
        this.initializeMap();
      }
    },
    sumTotalAmount() {
      let _ = 0;
      if (this.supply_?.amount) _ += Number(this.supply_?.amount);
      if (this.supply_?.amount2) _ += Number(this.supply_?.amount2);
      if (this.supply_?.lub_amount) _ += Number(this.supply_?.lub_amount);
      return _;
    },
    saveSupplyExecution() {
      const {
        uid,
        amount,
        amount2,
        lub_amount,
        odometer,
        quantity,
        quantity2,
        lub_quantity,
      } = this.supply_ || {};
      const _ = {
        uid,
        amount,
        amount2,
        lub_amount,
        odometer,
        quantity,
        quantity2,
        lub_quantity,
      };

      this.isLoadingSave = true;

      fetch(`${this.$store.state.apiUrl}cars/supplies/execution/${_?.uid} `, {
        credentials: "include",
        method: `PUT`,
        body: JSON.stringify(_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.togglePageDisplay();
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao salvar",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    initializeMap() {
      const self = this;
      setTimeout(() => {
        mapboxgl.accessToken = self.accessToken;
        self.mapBox = new mapboxgl.Map({
          container: "mapDiv",
          style: "mapbox://styles/jaconsmorais/ckw2qm6gk11bg15msgqn9ynji",
          center: [
            Number(self.supply.execution?.longitude),
            Number(self.supply.execution?.latitude),
          ],
          zoom: 15,
        });

        new mapboxgl.Marker({
          color: "#0d84ff",
          draggable: false,
        })
          .setLngLat([
            Number(self.supply.execution?.longitude),
            Number(self.supply.execution?.latitude),
          ])
          .addTo(self.mapBox);
      }, 100);
    },
    getImageSrc(imageUrl) {
      return `${this.$store.state.apiUrl.slice(
        0,
        this.$store.state.apiUrl.length - 1
      )}${imageUrl}`;
    },
    getPaddingLeftForSelectPrefix(prefix) {
      return `padding-left: ${(prefix?.length || 0) * 7.5}px;`;
    },
  },
  name: "CarSupplyOrderModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.avatar-uploader {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;

  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.mt-2 {
  margin-top: 8px;
}
.placeholder {
  margin: 40px 0 16px;
  line-height: 35px;
}
.input-prefix {
  margin-left: 10px !important;
}
</style>