<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                    <el-dropdown
                      @command="(c) => filterSupplyOrders(c)"
                      size="medium"
                      trigger="click"
                    >
                      <el-button type="primary" size="medium">
                        Exibir por: {{ displayPage || ""
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="Abertos">
                            <li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: displayPage === 'Abertos',
                              }"
                            >
                              Abertos
                            </li>
                          </el-dropdown-item>
                          <el-dropdown-item command="Vencidos"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: displayPage === 'Vencidos',
                              }"
                            >
                              Vencidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="Inválidos"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: displayPage === 'Inválidos',
                              }"
                            >
                              Inválidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="Finalizados"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: displayPage === 'Finalizados',
                              }"
                            >
                              Finalizados
                            </li></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-row>
                </el-col>
                <el-col :md="8">
                  <el-select
                    remote
                    value-key="uid"
                    clearable
                    class="m-0"
                    :loading="!cars"
                    @visible-change="fetchCars"
                    @change="selectCar"
                    v-model="car"
                    no-data-text="Não existem veículos cadastrados"
                    size="medium"
                  >
                    <template #prefix>Veículo:</template>
                    <el-option
                      v-for="item in cars"
                      :key="item.uid"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  :disabled="!car"
                  size="medium"
                  @click="openCarSupplyModal(null)"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Supplies.data"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="provider.name" label="fornecedor">
      </el-table-column>
      <el-table-column prop="driver.employee.name" label="motorista">
      </el-table-column>
      <el-table-column prop="car.pane" label="veículo"></el-table-column>
      <el-table-column prop="ref" label="código"></el-table-column>
      <el-table-column
        label="cadastro"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>
      <el-table-column
        v-if="displayPage === 'Finalizados'"
        label="odometro"
        prop="execution.odometer"
      >
      </el-table-column>
      <el-table-column
        v-if="displayPage === 'Finalizados'"
        label="abastecimento"
        :formatter="(r) => numberFormat.format(Number(r.execution?.quantity))"
      >
      </el-table-column>
      <el-table-column label="ações" :min-width="130">
        <template #default="supply">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                :disabled="isLoadingDownloadButton"
                v-show="displayPage != 'Finalizados'"
                onlyIcon
                @click="downloadReport(supply.row)"
                type="primary"
                circle
              >
                <i class="el-icon-download"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCarSupplyModal(supply.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                v-show="displayPage === 'Finalizados' && supply?.row?.bill"
                onlyIcon
                type="primary"
                circle
                @click="printBill(supply.row?.bill)"
              >
                <i class="el-icon-printer"></i
              ></el-button>
              <el-button
                v-show="displayPage === 'Finalizados' && !supply?.row?.bill"
                onlyIcon
                type="primary"
                circle
                @click="openBillModalFromSupplyOrder(supply.row)"
              >
                <i class="el-icon-s-order"></i
              ></el-button>
              <el-button
                v-show="supply.row.execution.status === 'completed'"
                onlyIcon
                type="success"
                circle
                @click="openCarSupplyOrderExecutionInfo(supply.row)"
              >
                <i class="el-icon-info"></i
              ></el-button>
              <el-popconfirm
                v-if="supply.row.execution.status != 'completed'"
                @confirm="checkAsCompleted(supply.row)"
                title="
              Marcar ordem como realizada? O motorista não poderá mais executar esse abastecimento
            "
              >
                <template #reference>
                  <el-button onlyIcon type="warning" circle>
                    <i class="el-icon-info"></i>
                  </el-button>
                </template>
              </el-popconfirm>
              <el-popconfirm
                @confirm="deleteCarSupply(supply.row)"
                title="
              Remover ordem de abastecimento?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Supplies?.lastPage"
    >
    </el-pagination>
    <vehicle-supply-order
      :showModal="showCarSupplyModal"
      @close-modal="showCarSupplyModal = false"
      @should-update="fetchCarSupplies"
      :car="car"
      :supply="car_supply"
    ></vehicle-supply-order>
    <vehicle-supply-order-execution-info
      v-if="showVehicleSupplyOrderExecutionInfo"
      :showModal="showVehicleSupplyOrderExecutionInfo"
      @close-modal="showVehicleSupplyOrderExecutionInfo = false"
      @should-update="fetchCarSupplies"
      :supply="car_supply"
    ></vehicle-supply-order-execution-info>
    <bill-modal
      :bill="bill"
      :showModal="showBillModal"
      @close-modal="showBillModal = false"
      @should-update="updateSupplyWithBill"
      @fetch-firms="firms = $event"
      @fetch-costs="costs = $event"
    ></bill-modal>
  </el-card>
</template>

<script>
import { maska } from "maska";
import VehicleSupplyOrder from "./modals/VehicleSupplyOrder.vue";
import { ElNotification } from "element-plus";
import SystemService from "../services/system";
import BillModal from "./modals/BillModal.vue";
import VehicleSupplyOrderExecutionInfo from "./modals/VehicleSupplyOrderExecutionInfo.vue";
import { notifyError, notifySuccess } from "../utils/notifiers";
//import { ElNotification } from "element-plus";
export default {
  name: "VehicleSupplies",
  data: () => ({
    hasError: false,
    showBillModal: false,
    firms: null,
    costs: null,
    bill: null,
    displayPage: "Abertos",
    isLoading: false,
    filterOptions: {},
    isLoadingDownloadButton: false,
    createMask: maska,
    showCarSupplyModal: false,
    showVehicleSupplyOrderExecutionInfo: false,
    supplies: [],
    currentPage: 1,
    car_supply: null,
    cars: null,
    car: null,
    numberFormat: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
  }),
  watch: {
    currentPage() {
      this.fetchCarSupplies();
    },
  },
  components: {
    VehicleSupplyOrder,
    VehicleSupplyOrderExecutionInfo,
    BillModal,
  },
  mounted() {
    this.filterSupplyOrders("Abertos");
  },
  methods: {
    checkAsCompleted(supply) {
      if (supply) {
        this.car_supply = supply;

        this.save({ status: "completed" });
      }
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchCarSupplies() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}supplies`);
      const params = {
        ...this.filterOptions,
        pagination: this.currentPage,
      };

      if (this.car) params["car_id"] = this.car?.uid;

      url.search = new URLSearchParams(params);

      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.supplies = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    filterSupplyOrders(filter) {
      this.currentPage = 1;
      this.displayPage = filter;
      switch (filter) {
        case "Vencidos":
          this.filterOptions["filterOption"] = "expired";
          break;
        case "Abertos":
          this.filterOptions["filterOption"] = "opened";
          break;
        case "Finalizados":
          this.filterOptions["filterOption"] = "finalized";
          break;
        case "Inválidos":
          this.filterOptions["filterOption"] = "invalid";
          break;
      }

      this.fetchCarSupplies();
    },
    fetchCars(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}cars`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.cars = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
    async printBill(bill) {
      const payment = bill?.payments[0];

      if (payment)
        if (payment) {
          if (this.$store.state.user.printer?.uid) {
            const { job } = await SystemService()
              .Printers(this.$store.state.user.printer.uid)
              .Job()
              .create({
                layout: "bills",
                print_content: JSON.stringify({
                  ...payment,
                  bill,
                }),
              });

            if (job) notifySuccess("Impressão enviada para a impressora");
            else
              notifyError(
                "Não foi possível enviar a impressão para a impressora"
              );
          } else {
            try {
              await SystemService()
                .Print()
                .Bill(
                  new URLSearchParams({
                    codConta: payment?.ref,
                    empresa: bill?.firm?.name,
                    fornecedor: bill?.partner?.name,
                    centroCusto: `${
                      bill?.cost?.category ? bill?.cost?.category : ""
                    }${
                      bill?.cost?.subcategory
                        ? " -> " + bill?.cost?.subcategory
                        : ""
                    }${bill?.cost?.name ? " -> " + bill?.cost?.name : ""}${
                      bill?.cost?.entity ? " -> " + bill?.cost?.entity : ""
                    }`,
                    dataAquisicao: new Date(
                      bill?.bought_at
                    ).toLocaleDateString(),
                    dataVencimento: new Date(
                      payment?.expires_at
                    ).toLocaleDateString(),
                    dataVencimentoSobra: "-",
                    valor: this.currencyFormatter.format(
                      Number(payment?.amount)
                    ),
                    descricao: bill?.obs || "-",
                    numeroParcela: 1,
                    qtdParcela: bill?.quota || "1",
                  })
                );

              notifySuccess("Impressão enviada para a impressora");
            } catch (e) {
              notifyError(
                "Ocorreu um erro ao enviar a impressão para a impressora"
              );
            }
          }
        }
    },
    openBillModalFromSupplyOrder(supply) {
      this.car_supply = supply;
      this.bill = {
        bought_at: new Date(supply.execution?.updated_at),
        amount: this.sumTotalAmount(supply?.execution),
        partner: supply?.provider,
      };

      if (supply?.car?.cost?.length) this.bill.cost = supply?.car?.cost?.[0];

      this.showBillModal = true;
    },
    updateSupplyWithBill(bill) {
      if (bill) {
        this.car_supply.bill_id = bill.uid;
        this.save({ bill_id: bill.uid });
      }
    },
    save(supply) {
      if (supply) {
        fetch(
          `${this.$store.state.apiUrl}cars/${this.car_supply?.car?.uid}/supplies/${this.car_supply?.uid}`,
          {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(supply),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchCarSupplies();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a ordem",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
      }
    },
    sumTotalAmount(e) {
      let _ = 0;
      if (e?.amount) _ += Number(e?.amount);
      if (e?.amount2) _ += Number(e?.amount2);
      if (e?.lub_amount) _ += Number(e?.lub_amount);
      return _;
    },
    openCarSupplyModal(v) {
      this.car_supply = v;
      if (v?.car) this.car = v?.car;
      this.showCarSupplyModal = true;
    },
    openCarSupplyOrderExecutionInfo(v) {
      this.car_supply = v;
      this.showVehicleSupplyOrderExecutionInfo = true;
    },
    deleteCarSupply(supply) {
      fetch(
        `${this.$store.state.apiUrl}cars/${this.car?.uid}/supplies/${supply?.uid}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      )
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() =>
          ElNotification.success({
            title: "Ordem de abastecimento removida com sucesso",
          })
        )
        .catch(async (e) =>
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          })
        )
        .finally(() => this.fetchCarSupplies());
    },
    downloadReport(supply) {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      fetch(
        `${this.$store.state.apiUrl}cars/${this.car?.uid}/supplies/${supply?.uid}/report`,
        {
          credentials: "include",
          headers: {
            Accept: "blob",
          },
        }
      )
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Ordem de Abastecimento - ${supply?.car?.name}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .finally(() => this.changeDownloadButtonStatus());
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    selectCar(v) {
      this.car = v;
      this.fetchCarSupplies();
    },
  },
  computed: {
    Supplies() {
      return this.supplies || {};
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.m-0 {
  margin-top: 0px !important;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
</style>
